import { Pipe, PipeTransform } from '@angular/core'
import { CurrencyPipe } from '@angular/common'

@Pipe({
  name: 'curr',
})
export class CurrPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  transform(value: string | number): string {
    return this.currencyPipe.transform(value, undefined, 'symbol', '1.0-0')
  }
}
