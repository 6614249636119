import { Component, Inject, Input } from '@angular/core'
import { finalize } from 'rxjs/operators'
import { NEXT_URL, SharedService } from '../../services/shared.service'
import { WindowToken } from '../../../app.module'
import { ApiService } from '../../services/api.service'
import { TranslateModule } from '@ngx-translate/core'
import { CommonModule, NgOptimizedImage } from '@angular/common'

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule, NgOptimizedImage],
  selector: 'app-bankid-authenticator',
  templateUrl: './bankid-authenticator.component.html',
  styleUrls: ['./bankid-authenticator.component.scss'],
})
export class BankidAuthenticatorComponent {
  @Input() redirectUrl = ''

  submitting = false

  constructor(
    public api: ApiService,
    private sharedService: SharedService,
    @Inject(WindowToken) private window: Window
  ) {}

  continue() {
    this.submitting = true
    this.api
      .getBankIdLink()
      .pipe(finalize(() => (this.submitting = false)))
      .subscribe(async ({ link }) => {
        this.sharedService.setData(NEXT_URL, this.redirectUrl)
        this.window.location.href = link
      })
  }
}
