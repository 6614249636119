import { Pipe, PipeTransform } from '@angular/core'
import { BankItem } from '../interfaces'

@Pipe({
  name: 'banksFilter',
})
export class BanksFilterPipe implements PipeTransform {
  transform(banks: BankItem[], search: string = ''): BankItem[] {
    return search === ''
      ? banks
      : banks.filter(bank => {
          const s = search.toLowerCase()
          return (
            bank.id.toLowerCase().includes(s) ||
            bank.name.toLowerCase().includes(s) ||
            bank.regNumbers.find(number => number.startsWith(s)) ||
            bank.bic.toLowerCase().includes(s)
          )
        })
  }
}
