@if (loading) {
<app-loading></app-loading>
} @else {
<div class="form-group">
  <input type="text" [(ngModel)]="filter" class="form-control" [placeholder]="'BanksList.input' | translate" />
</div>
@if (banksFiltered.length) {
<section class="banks">
  @for (bank of banksFiltered; track bank) {
  <a href="#" (click)="selectBank(bank.id); $event.preventDefault()" [title]="bank.name"
    ><img [src]="bank.logo" [alt]="bank.name" loading="lazy" /><span>{{ bank.name }}</span></a
  >
  }
</section>
} @if (!banksFiltered.length) {
<p>{{ 'BanksList.notFound' | translate }}</p>
} }
