export {}

declare global {
  interface Array<T> {
    range(min: T, max: T, step: T): Array<T>
  }
}

/* istanbul ignore else */
if (!Array.prototype.range) {
  Array.prototype.range = (from, to, step) =>
    step ? [...Array(Math.floor((to - from) / step) + 1)].map((_, i) => from + i * step) : []
}
