import { Component, Inject, Input, OnInit } from '@angular/core'
import { ApiService } from '../../services/api.service'
import { BankItem } from '../../interfaces'
import { REQUISITION_STORAGE, SharedService } from '../../services/shared.service'
import { WindowToken } from '../../types'
import { BanksFilterPipe } from '../../pipes/banks-filter.pipe'
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-banks-list',
  templateUrl: './banks-list.component.html',
  styleUrls: ['./banks-list.component.scss'],
})
export class BanksListComponent implements OnInit {
  @Input() callbackUrl: string

  banks: BankItem[] = []
  filter = ''
  loading = true
  success

  constructor(
    private route: ActivatedRoute,
    public api: ApiService,
    private sharedService: SharedService,
    @Inject(WindowToken) private window: Window,
    private banksFilter: BanksFilterPipe
  ) {
    this.success = route.snapshot.queryParams.success
  }

  get banksFiltered() {
    return this.banksFilter.transform(this.banks, this.filter)
  }

  ngOnInit() {
    this.api.getBanksList().subscribe(
      banksList => {
        this.banks = banksList
        this.loading = false
      },
      () => (this.loading = false)
    )
  }

  selectBank(id: string) {
    this.api.getBankVerificationLink(id, this.callbackUrl).subscribe(response => {
      this.sharedService.setData(REQUISITION_STORAGE, response.requisition)
      this.window.location.href = response.link
    })
  }
}
