import { AfterViewInit, Directive, ElementRef, HostListener, Renderer2 } from '@angular/core'

@Directive({
  selector: '[appPhoneCodeInput]',
})
export class PhoneCodeInputDirective implements AfterViewInit {
  constructor(private el: ElementRef, private r: Renderer2) {}

  @HostListener('focus', ['$event']) onFocus() {
    this.el.nativeElement.select()
  }

  @HostListener('keyup', ['$event']) onKeydown() {
    // istanbul ignore else
    if (this.el.nativeElement.value) {
      const next = this.r.nextSibling(this.el.nativeElement)
      if (next) next.focus()
      else this.el.nativeElement.blur()
    }
  }

  ngAfterViewInit(): void {
    if (this.r.parentNode(this.el.nativeElement).firstChild === this.el.nativeElement) this.el.nativeElement.focus()
  }
}
