import { Injectable } from '@angular/core'
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { BankAuthData, Customer, LoginRequestDto } from '../interfaces'
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'
import { BankAuthScope } from '../types'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  error$: Subject<string> = new Subject<string>()
  authorized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  constructor(private http: HttpClient) {
    this.authorized$.next(this.isAuthenticatedDashboard())
  }

  get isAuthorizedViaBank() {
    return (
      this.isAuthenticated() &&
      [BankAuthScope.ProfileVerificationHome, BankAuthScope.ProfileVerification].includes(
        this.user.bank_authorization_type as BankAuthScope
      ) &&
      this.user.bank_authorization_time &&
      !Number(this.user.bank_authorization_expired) &&
      this.user.bank_authorization_data !== ''
    )
  }

  get hadBankAuthorization() {
    return Boolean(this.user.bank_authorization_data)
  }

  get user(): Customer | null {
    return JSON.parse(localStorage.getItem('user'))
  }

  setUser(response) {
    if (response && response.user) {
      localStorage.setItem('user', JSON.stringify(response.user))
      if (this.isAuthenticatedDashboard()) {
        this.authorized$.next(true)
      }
    } else {
      localStorage.removeItem('user')
    }
  }

  fetchErrorFromResponse(errorResponse: HttpErrorResponse): string {
    return errorResponse?.error?.error?.alias ?? errorResponse.message
  }

  handleError(errorResponse: HttpErrorResponse) {
    const error = this.fetchErrorFromResponse(errorResponse)
    this.error$.next(error)
    return throwError(errorResponse)
  }

  login(user: LoginRequestDto): Observable<any> {
    return this.http
      .post(`/api/public/v1/users/login/ssn`, { user })
      .pipe(tap(this.setUser.bind(this)), catchError(this.handleError.bind(this)))
  }

  authorizeUserWithBankData(data: BankAuthData): Observable<any> {
    return this.http
      .post(`/api/public/v1/users/authorize/bank`, { data })
      .pipe(tap(this.setUser.bind(this)), catchError(this.handleError.bind(this)))
  }

  logout(): Observable<any> {
    this.authorized$.next(false)
    const user = this.user
    if (user) {
      this.setUser({ user: null })
    }
    return user
      ? this.http.post(
          `/api/public/v1/users/logout`,
          {},
          {
            params: { token: user.token },
          }
        )
      : new Observable()
  }

  isAuthenticated(): boolean {
    return Boolean(this.user ?? false)
  }

  isAuthenticatedDashboard(): boolean {
    return Boolean(this.user && this.user?.token && this.user.token.startsWith('d_'))
  }
}
