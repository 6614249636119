import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CustomerNavigationService } from './customer-navigation.service'
import { ApiService } from '../shared/services/api.service'

@NgModule({
  imports: [CommonModule],
  providers: [CustomerNavigationService, ApiService],
})
export class CustomerNavigationModule {}
