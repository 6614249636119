// istanbul ignore file

import { InjectionToken } from '@angular/core'

export enum HomeOwnership {
  Rental = 'Lejebolig',
  OwnerOccupiedHousing = 'Ejerbolig',
  CorporativeHousing = 'Andelsbolig',
  HolidayHome = 'Fritidsbolig',
  NoPermanentPlaceOfResidence = 'Uden fast bopæl',
}

export enum Purpose {
  OtherDebt = 'Anden gæld',
  Dentist = 'Tandlæge',
  Travel = 'Rejse',
  Bills = 'Regninger',
  Consumption = 'Forbrug',
  Medicine = 'Medicin',
}

export enum MaritalStatus {
  Married = 'Gift',
  Unmarried = 'Eneboende',
  InCohabitation = 'Samlevende',
}

export enum LowHouseCostReason {
  HousingReducedFromSalary = 'HousingReducedFromSalary',
  IncludedInLoanCost = 'IncludedInLoanCost',
  LivingWithFamily = 'LivingWithFamily',
  LivingWithFriend = 'LivingWithFriend',
  OwnValue = 'OwnValue',
  PartnerPaysForHousing = 'PartnerPaysForHousing',
  PayingRentInCash = 'PayingRentInCash',
  PaymentInAnotherAccount = 'PaymentInAnotherAccount',
  TransferredUsingMobilePay = 'TransferredUsingMobilePay',
}

export enum BankLoginMethods {
  mitid = 'mitid',
  nemid = 'nemid',
}

export const WindowToken = new InjectionToken<Window>('Window object', {
  providedIn: 'root',
  factory: () => window,
})

export enum AccountLetterPaymentStatuses {
  NotPaidInTime = 'NotPaidInTime',
  NotYetDue = 'NotYetDue',
  PaidInTime = 'PaidInTime',
}

export enum AccountLetterTypes {
  Cancellation = 'Cancellation',
  Notice = 'Notice',
  Reminder = 'Reminder',
}

export enum AppStatus {
  dashboard = 'dashboard',
  declined = 'declined',
  newApp = 'new',
  resubmit = 'resubmit',
  resume = 'resume',
}
