import './shared/array.prototype'
import { Component } from '@angular/core'
import { CustomerNavigationService } from './customer-navigation/customer-navigation.service'
import { ApiService } from '@shared/services/api.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    public api: ApiService,
    /*private store: Store,*/
    private customerNavigationService: CustomerNavigationService
  ) {
    customerNavigationService.init()
    /*api.getFastPaymentStatus().subscribe(({ status }) => {
      if (!status) {
        this.store.dispatch(AppActions.disableFastPayments())
      }
    })*/
  }
}
