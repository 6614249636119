import { Injectable } from '@angular/core'
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Bank, CustomerExtended, User } from '../interfaces'
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs'
import { catchError, finalize, tap } from 'rxjs/operators'
import { environment } from '@environments/environment'
import { Router } from '@angular/router'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  error$: Subject<string> = new Subject<string>()
  login$: BehaviorSubject<boolean> = new BehaviorSubject(false)
  logout$: BehaviorSubject<boolean> = new BehaviorSubject(false)

  constructor(private http: HttpClient, private router: Router) {}

  get user(): CustomerExtended {
    return JSON.parse(localStorage.getItem('user'))
  }

  setUser(response) {
    if (response && response.user) {
      localStorage.setItem('user', JSON.stringify(response.user))
      this.login$.next(true)
    } else {
      localStorage.removeItem('user')
    }
  }

  fetchErrorFromResponse(errorResponse: HttpErrorResponse): string {
    return errorResponse?.error?.error?.alias ?? errorResponse.message
  }

  handleError(errorResponse: HttpErrorResponse) {
    const error = this.fetchErrorFromResponse(errorResponse)
    this.error$.next(error)
    return throwError(error)
  }

  login(user: User): Observable<any> {
    return this.http
      .post(`${environment.apiHost}/api/v1/users/login/email`, { user })
      .pipe(tap(this.setUser.bind(this)), catchError(this.handleError.bind(this)))
  }

  authorizeBank(data: Bank): Observable<any> {
    return this.http
      .post(`${environment.apiHost}/api/public/v1/users/authorize/bank`, { data })
      .pipe(tap(this.setUser.bind(this)), catchError(this.handleError.bind(this)))
  }

  logout(): Observable<any> {
    const user = this.user
    this.logout$.next(true)
    return user
      ? this.http.post(`${environment.apiHost}/api/v1/users/logout`, {}).pipe(
          finalize(async () => {
            this.setUser({ user: null })
            await this.router.navigateByUrl('/dashboard/login')
          })
        )
      : new Observable()
  }

  isAuthenticated(): boolean {
    return Boolean(this.user ?? false)
  }

  isAuthenticatedDashboard(): boolean {
    return Boolean(this.user && this.user?.token && this.user.token.startsWith('d_'))
  }
}
