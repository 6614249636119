import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'getAccount',
})
export class AccountPipe implements PipeTransform {
  transform(value: string, type: 'account1' | 'account2'): string {
    return type === 'account1' ? value.substring(0, 4) : value.substring(4)
  }
}
