import { Component, OnInit } from '@angular/core'
import { ConfigService } from '../../../shared/services/config.service'
import { ApiService } from '../../../shared/services/api.service'
import { ServiceNotification } from '../../../shared/interfaces'

@Component({
  selector: 'app-flow-layout',
  templateUrl: './flow-layout.component.html',
  styleUrls: ['./flow-layout.component.scss'],
})
export class FlowLayoutComponent implements OnInit {
  serviceNotification: ServiceNotification

  constructor(public config: ConfigService, public api: ApiService) {}

  ngOnInit() {
    this.api.getServiceNotification().subscribe(response => {
      this.serviceNotification = response
    })
  }
}
