import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private readonly environmentProxy

  constructor() {
    this.environmentProxy = new Proxy(environment, {
      get: (target, prop) => {
        if (prop.toString().indexOf('#') !== -1) {
          const p = prop.toString().split('#')
          return `${target[p[0]]}${p[1]}`
        }

        if (prop.toString().indexOf('_') !== -1) {
          const p = prop.toString().split('_')
          return `${p[0]}:${target[p[1]]}`
        }
        return target[prop]
      },
    })
  }

  getSetting(setting: string) {
    return this.environmentProxy[setting]
  }
}
