import { createAction } from '@ngrx/store'

export namespace AppActions {
  export const disablePaymentButton = createAction('[Dashboard Layout Component] Disable payment button')
  export const enablePaymentButton = createAction('[Dashboard Layout Component] Enable payment button')
  export const disableFastPayments = createAction('[Flow] Disable fast payments')
  export const enableFastPayments = createAction('[Flow] Enable fast payments')
  export const hideCardMenu = createAction('[Dashboard Layout] hideCardMenu')
  export const showCardMenu = createAction('[Dashboard Layout] showCardMenu')
  export const hideDashboardLink = createAction('[Flow Layout] Hide dashboard link')
  export const showDashboardLink = createAction('[Flow Layout] Show dashboard link')
}
