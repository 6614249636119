import { InjectionToken } from '@angular/core'

export enum InvoiceType {
  email = 'email_invoice',
  paper = 'paper_invoice',
}

export enum BankAuthScope {
  Login = 'login',
  MasterCardConversionVerification = 'masterCardConversionVerification',
  ProfileVerification = 'profileVerification',
  ProfileVerificationHome = 'profileVerificationHome',
  TransactionsVerification = 'profileVerificationTransactions',
  Sign = 'sign',
  UserVerificationHome = 'userVerificationHome',
}

export enum Employment {
  Employed = 'Työsuhteessa',
  Entrepreneur = 'Yrittäjä',
  Pensioner = 'Eläkeläinen',
  Student = 'Opiskelija',
  Unemployed = 'Työtön',
  Farmer = 'Maanviljelijä',
}

export enum Education {
  PrimarySchool = 'Peruskoulu',
  SecondarySchool = 'Ylioppilas',
  FurtherQualification = 'Ammattitutkinto',
  UndergraduateDegree = 'Alempi korkeakoulututkinto',
  MastersDegree = 'Ylempi korkeakoulututkinto',
  Other = 'Muu',
}

export enum HomeOwnership {
  OwnApartment = 'Oma asunto',
  RentalApartment = 'Vuokra-asunto',
  RightOfOccupancyApartment = 'Asumisoikeusasunto',
  SpouseOwnedApartment = 'Puolison omistama asunto',
  AtParents = 'Vanhempien luona',
  EmployeeHousing = 'Työsuhdeasunto',
  Other = 'Muu',
}

export enum HomeType {
  TownHouse = 'Omakotitalo',
  ApartmentHouse = 'Kerrostalo',
  TerracedHouse = 'Rivitalo',
  Other = 'Muu',
}

export enum LoanReason {
  Purchase = 'Kertaluontoinen hankinta',
  DailyExpenses = 'Päivittäiset menot',
  OtherLoans = 'Muut lainat / lainojen yhdistäminen',
  HealthCare = 'Terveydenhoito',
  LeisureAndTravel = 'Vapaa-aika ja matkustaminen',
  Study = 'Opiskelu',
  CarAndTraffic = 'Auto ja liikenne',
  Investment = 'Sijoittaminen',
  Other = 'Muu',
}

export enum MaritalStatus {
  Married = 'Naimisissa',
  Unmarried = 'Naimaton',
  InCohabitation = 'Avoliitossa',
  Divorced = 'Eronnut',
  Widow = 'Leski',
}

export enum TransactionStatus {
  ACCRUED_INTEREST = 'ACCRUED_INTEREST',
  ATM_FEE = 'ATM_FEE',
  CANCELLATION = 'CANCELLATION',
  CARD_FEE = 'CARD_FEE',
  CREDIT = 'CREDIT',
  CREDIT_CORONA = 'CREDIT_CORONA',
  DC_LATE_INTEREST = 'DC_LATE INTEREST',
  DC_LATE_INTEREST_PAYMENT = 'DC_LATE INTEREST PAYMENT',
  FAST_FEE = 'FAST_FEE',
  FEE = 'FEE',
  FEE_MONTHLY = 'FEE_MONTHLY',
  FEE_MONTHLY_PAPER = 'FEE_MONTHLY_PAPER',
  GRANTED = 'GRANTED',
  INFO = 'INFO',
  INTEREST = 'INTEREST',
  LATE_INTEREST = 'LATE_INTEREST',
  LOAN = 'LOAN',
  LOAN_CORONA = 'LOAN_CORONA',
  MC_CLEARANCE = 'MC_CLEARANCE',
  MC_CLEARED_CANCELATION = 'MC_CLEARED_CANCELATION',
  MC_DENY = 'MC_DENY',
  MC_PREAUTH = 'MC_PREAUTH',
  MC_PREAUTH_CANCELED = 'MC_PREAUTH_CANCELED',
  MC_PREAUTH_REVERSAL = 'MC_PREAUTH_REVERSAL',
  MC_REFUND = 'MC_REFUND',
  MOVE_DUE_FEE = 'MOVE_DUE_FEE',
  PAYMENT = 'PAYMENT',
  PENALTY = 'PENALTY',
  PLANNED_FEE = 'PLANNED_FEE',
  PLANNED_INTEREST = 'PLANNED_INTEREST',
  RISE_FEE = 'RISE_FEE',
  UPDATE_INTEREST = 'UPDATE_INTEREST',
}

export const WindowToken = new InjectionToken<Window>('Window object', {
  providedIn: 'root',
  factory: () => window,
})

export type CreditCardType = 'W' | 'N' | ''

export enum CreditCard {
  masterCard = 'N',
  visa = 'W',
}
